import type { FC } from 'react';
import { useTranslation } from 'next-i18next';

import type { SpecialBadgeProps } from './SpecialBadge.types';
import { getSkewClass } from './SpecialBadge.utils';

import styles from './SpecialBadge.module.scss';

const SpecialBadge: FC<SpecialBadgeProps> = ({ badge, skew }) => {
  const { t } = useTranslation('casino');

  if (!badge) {
    return null;
  }

  if (badge === 'vip') {
    return (
      <div className={cn(styles.badge, styles.vip, getSkewClass(skew || 'right'))}>
        {t('tournament.card.badge.vip', 'VIP')}
      </div>
    );
  }

  if (badge === 'exclusive') {
    return (
      <div className={cn(styles.badge, getSkewClass(skew || 'left'))}>
        {t('tournament.card.badge.exclusive', 'Exclusive')}
      </div>
    );
  }

  return null;
};

export { SpecialBadge };
