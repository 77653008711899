import type { ButtonSkewVariant } from '@/components/Button/Button.types';

import styles from './SpecialBadge.module.scss';

export const getSkewClass = (skew: ButtonSkewVariant) => {
  if (skew === 'right') {
    return styles.rightSkew;
  }

  return styles.leftSkew;
};
