import type { FC } from 'react';
import { zeroPad } from '@mwl/core-lib';

import { Counter } from '@/components/Counter/Counter';
import { useTimerTranslations } from '@/hooks/useTimerTranslations';
import { getTimerCompactValues } from '@/utils/getTimerCompactValues';

import type { RendererProps } from './Renderer.types';

import styles from './Renderer.module.scss';

export const Renderer: FC<RendererProps> = ({ delta, size, className, disabled, place, status, classes }) => {
  const timerTranslations = useTimerTranslations();

  const { days: daysValue, hours: hoursValue, minutes: minutesValue, seconds: secondsValue } = delta;

  const { leftTimerValue, rightTimerValue } = getTimerCompactValues(
    { value: Number(daysValue), translation: timerTranslations.d },
    { value: Number(hoursValue), translation: timerTranslations.h },
    { value: Number(minutesValue), translation: timerTranslations.m },
    { value: Number(secondsValue), translation: timerTranslations.s },
  );

  if (size === 'small') {
    return (
      <div className={cn(styles.root, styles.compact, className)}>
        {!!status && <div className={styles.status}>{status}</div>}
        {place}
        <div className={styles.countdown}>
          {leftTimerValue}
          <span className={styles.dots}>:</span>
          {rightTimerValue}
        </div>
      </div>
    );
  }

  return (
    <>
      {place}
      <div className={cn(styles.root, className)}>
        {!!status && <div className={styles.status}>{status}</div>}
        <div className={styles.countdown}>
          {daysValue > 0 && (
            <Counter
              className={cn(styles.counter, classes?.counter)}
              topContent={disabled ? '--' : zeroPad(daysValue, 2)}
              bottomContent={timerTranslations.day}
            />
          )}
          {(hoursValue > 0 || daysValue > 0) && (
            <Counter
              className={cn(styles.counter, classes?.counter)}
              topContent={disabled ? '--' : zeroPad(hoursValue, 2)}
              bottomContent={timerTranslations.hrs}
            />
          )}
          <Counter
            className={cn(styles.counter, classes?.counter)}
            topContent={disabled ? '--' : zeroPad(minutesValue, 2)}
            bottomContent={timerTranslations.min}
          />
          {daysValue < 1 && hoursValue < 1 && (
            <Counter
              className={cn(styles.counter, classes?.counter)}
              topContent={disabled ? '--' : zeroPad(secondsValue, 2)}
              bottomContent={timerTranslations.sec}
            />
          )}
        </div>
      </div>
    </>
  );
};
