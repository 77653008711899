import type { FC } from 'react';
import { memo } from 'react';

import { useMediaWidthDown } from '@/hooks/useMediaWidthDown';

import type { CounterProps } from './Counter.types';

import styles from './Counter.module.scss';

const BaseCounter: FC<CounterProps> = ({ topContent, bottomContent, size, className, classes }) => {
  const isXsScreen = useMediaWidthDown('screenXs');

  const calculatedSize = size || (isXsScreen ? 'mobile' : 'desktop');

  return (
    <div className={cn(styles.counter, styles[calculatedSize], className)}>
      <div className={styles.back}>
        <div className={cn(styles.top, classes?.counterTop)} />
        <div className={cn(styles.bottom, classes?.counterBottom)} />
      </div>
      <div className={styles.content}>
        <div className={cn(styles.top, classes?.contentTop)}>{topContent}</div>
        <div className={cn(styles.bottom, classes?.contentBottom)}>{bottomContent}</div>
      </div>
    </div>
  );
};

const Counter = memo(BaseCounter);

export * from './Counter.types';
export { Counter };
