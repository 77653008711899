import { zeroPad } from '@mwl/core-lib';

/**
 * Возвращает строковое представление таймера в компактном виде.
 */
export const getTimerCompactValues = (
  days: { value: number; translation: string },
  hours: { value: number; translation: string },
  minutes: { value: number; translation: string },
  seconds: { value: number; translation: string },
) => {
  if (days.value === 0) {
    if (hours.value === 0) {
      return {
        leftTimerValue: `${zeroPad(minutes.value, 2)}${minutes.translation}`,
        rightTimerValue: `${zeroPad(seconds.value, 2)}${seconds.translation}`,
      };
    }

    return {
      leftTimerValue: `${zeroPad(hours.value, 2)}${hours.translation}`,
      rightTimerValue: `${zeroPad(minutes.value, 2)}${minutes.translation}`,
    };
  }

  return {
    leftTimerValue: `${zeroPad(days.value, 2)}${days.translation}`,
    rightTimerValue: `${zeroPad(hours.value, 2)}${hours.translation}`,
  };
};
