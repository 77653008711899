import type { FC } from 'react';
import { useCallback } from 'react';
import type { CountdownTimeDelta } from 'react-countdown';
import Countdown from 'react-countdown';

import { Renderer } from './Renderer/Renderer';
import type { TournamentTimerProps } from './TournamentTimer.types';

const TournamentTimer: FC<TournamentTimerProps> = ({ size, place, status, disabled, className, classes, ...rest }) => {
  const renderer = useCallback(
    (delta: CountdownTimeDelta) => (
      <Renderer
        delta={delta}
        size={size}
        place={place}
        status={status}
        disabled={disabled}
        className={className}
        classes={classes}
      />
    ),
    [className, disabled, place, size, status, classes],
  );

  return <Countdown renderer={renderer} {...rest} />;
};

export { TournamentTimer };
