import { useTranslation } from 'next-i18next';

export const useTimerTranslations = () => {
  const { t } = useTranslation('common');

  return {
    days: t('tournament.card.days', 'days'),
    day: t('tournament.card.day', 'day'),
    d: t('tournament.card.d', 'd'),
    hours: t('tournament.card.hours', 'hours'),
    hrs: t('tournament.card.hrs', 'hrs'),
    h: t('tournament.card.h', 'h'),
    minutes: t('tournament.card.minutes', 'minutes'),
    min: t('tournament.card.min', 'min'),
    m: t('tournament.card.m', 'm'),
    seconds: t('tournament.card.seconds', 'seconds'),
    sec: t('tournament.card.sec', 'sec'),
    s: t('tournament.card.s', 's'),
  };
};
